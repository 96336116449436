<template>
  <div>
    <NotificationForm />

    <v-card color="indigo lighten-5">
      <div style="width: 100%; height: 20px"></div>
    </v-card>

    <v-card>
      <PushNotificationsLogs :preset-is-one-time="[1]" />
    </v-card>
  </div>
</template>

<script>
import PushNotificationsLogs from '@/components/support/push_notification/PushNotificationsLogs.vue'

export default {
  name: 'InstantNotification',

  components: {
    PushNotificationsLogs,
    NotificationForm: () => import('@/components/support/push_notification/NotificationForm'),
  },
}
</script>
